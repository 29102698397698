import React, { Component } from 'react'
import "./AboutSectionStyle.css"

export default class AboutSection extends Component {
  render() {
    return (
        <section className="home-about-area">
        <div className='title-section about-title'>
            <h1>About Me</h1>
        </div>
        </section>
    )
  }
}
